import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  colspan: "8",
  class: "text-center"
}
const _hoisted_2 = { class: "font-weight-bold" }
const _hoisted_3 = { class: "font-weight-normal" }
const _hoisted_4 = { class: "font-weight-normal" }
const _hoisted_5 = { class: "font-weight-normal" }
const _hoisted_6 = { class: "font-weight-normal" }
const _hoisted_7 = { class: "font-weight-normal" }
const _hoisted_8 = { class: "dropdown" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "dropdown-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("tr", null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("td", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("span", {
            class: "font-weight-bolder",
            style: {"font-size":"1.5em"}
          }, "Loading ...", -1)
        ])))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("td", null, [
            _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.dt.id), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_component_router_link, {
                to: `/settings/entries/kyc/${_ctx.dt.id}`,
                class: "text-blue"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.dt.level), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.dt.cummulative_withdrawer_limit), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.dt.daily_withdrawer_limit), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.dt.description), 1)
          ]),
          _createElementVNode("td", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dt.requirements, (req) => {
              return (_openBlock(), _createElementBlock("span", {
                class: "font-weight-normal mx-1",
                key: req
              }, [
                _createTextVNode(_toDisplayString(req) + " ", 1),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.dt.requirements.length > 0 ? 'block': 'hidden')
                }, ",", 2)
              ]))
            }), 128))
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.dt.created_at), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", {
                class: "btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0",
                type: "button",
                id: "dropdownMenuButton",
                "data-bs-toggle": "dropdown",
                "aria-expanded": "false",
                disabled: _ctx.loading
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("span", { class: "icon icon-sm" }, [
                  _createElementVNode("span", { class: "fas fa-ellipsis-h icon-dark" })
                ], -1),
                _createElementVNode("span", { class: "sr-only" }, "Toggle Dropdown", -1)
              ]), 8, _hoisted_9),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_router_link, {
                  to: `/settings/entries/kyc/${_ctx.dt.id}`,
                  class: "dropdown-item"
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createElementVNode("span", { class: "fas fa-edit mr-1" }, null, -1),
                    _createTextVNode(" Update KYC ")
                  ])),
                  _: 1
                }, 8, ["to"])
              ])
            ])
          ])
        ], 64))
  ]))
}